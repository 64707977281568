import React from 'react'
import {BrowserRouter, Route, Switch, Redirect, withRouter } from 'react-router-dom'
import StickerPage from './pages/sticker-page/stickerPage'
import './styles/index.scss'

export default function Apps(){
  return(
    <BrowserRouter>
      <Route
        path="/sticker/:id"
        component={(props)=>{
          return(
            <StickerPage id={props.match.params.id} />
          )
        }}
      />
      <Route
        path="*"
        render={() => <>{/*window.location.href= "https://ipacket.us"*/}</>}
      />
    </BrowserRouter>
  )
}
