import React, {useEffect, useState} from 'react'
import {getStickerInfo, stickerDownloadURL} from "./state/stickerPageActions"
import {TEST_STICKER_INFO} from "./state/testStickerInfo"
import './stickerPullPage.scss'
import FallBackImage from '../../assets/images/fallback_ipacket_folder.png'
import PrintIcon from '../../assets/icons/printicon'

export default function StickerPage(props){
  const urlParams = new URLSearchParams(window.location.search);
  const jwt = urlParams.get('jwt')
  const tk = urlParams.get('token')

  const token = ()=>{
    if(tk){return tk}
    return(jwt)
  }

  const {id} = props
  const [stickerInfo, setStickerInfo] = useState(null)
  const [loading, setLoading] = useState(true)
  const [errorLoading, setErrorLoading] = useState(false)

  useEffect(()=>{

    getStickerInfo(id,token()).then((response)=>{
      // if(enableTest){
      //   return(TEST_STICKER_INFO[0])
      // }
      if(response.status===200){
          return response.json()
      }
      else{
        //TODO: Throw Sentry Error
        setErrorLoading(true)
        setLoading(false)
      }
    }).then(res=>{
      if(res){
        setStickerInfo(res)
      }
      setLoading(false)
    })
  },[])

  const VehicleDetailsItem = (props)=>{
    return(
        <div className="sticker-vehicle-details-item">
          <div className="sticker-vehicle-details-item-title">
            {props.title}
          </div>
          <div className="sticker-vehicle-details-item-info">
            {props.detail}
          </div>
        </div>
    )
  }
  const Sticker = ()=>{
    let enNumFormatter = Intl.NumberFormat('en-US')
    const skinStyleString = `
      body .sticker-header .sticker-suggested-price-container .sticker-suggested-price{
        background-color: ${stickerInfo.data_details.primary_color};
        color: ${stickerInfo.data_details.secondary_color};
      }
      .sticker-header .sticker-suggested-price-container .sticker-suggested-price:before{
        border-color: transparent transparent ${stickerInfo.data_details.primary_color};
      }
      body .sticker-body .sticker-added-options-container .sticker-added-options{
        background-color: ${stickerInfo.data_details.primary_color+'1A'};
      }
      body .sticker-page-controls .sticker-page-controls-print:hover{
        background-color: ${stickerInfo.data_details.primary_color};
      }
      .sticker-page-controls .sticker-page-controls-print:hover svg{
        fill: ${stickerInfo.data_details.secondary_color};
      }
    `
    return(
      <div className="sticker-container">
        <style>
          {skinStyleString}
        </style>
        <div className="sticker">
          <div className="sticker-header">
            <div className="sticker-logo-container">
              {stickerInfo.vehicle_details.logo?
                <img className="sticker-logo" src={stickerInfo.vehicle_details.logo}/>
                :
                <img className="sticker-logo" src={FallBackImage}/>
              }
            </div>
            <div className="sticker-vehicle-details-container">
              <div className="sticker-vehicle-details">
                <div className="sticker-vehicle-details-ymm">
                  {`${stickerInfo.vehicle_details.year} ${stickerInfo.vehicle_details.make} ${stickerInfo.vehicle_details.model?stickerInfo.vehicle_details.model:""}`}
                </div>
                <div className="sticker-vehicle-details-items">
                  {
                    stickerInfo.vehicle_details.vin?
                    <VehicleDetailsItem title="VIN" detail={stickerInfo.vehicle_details.vin}/>
                    :
                    <React.Fragment/>
                  }
                  {
                    stickerInfo.vehicle_details.trim?
                    <VehicleDetailsItem title="TRIM" detail={stickerInfo.vehicle_details.trim}/>
                    :
                    <React.Fragment/>
                  }
                  {
                    stickerInfo.vehicle_details.engine?
                    <VehicleDetailsItem title="ENGINE" detail={stickerInfo.vehicle_details.engine}/>
                    :
                    <React.Fragment/>
                  }
                  {
                    stickerInfo.vehicle_details.transmission?
                    <VehicleDetailsItem title="TRANSMISSION" detail={stickerInfo.vehicle_details.transmission}/>
                    :
                    <React.Fragment/>
                  }
                  {
                    stickerInfo.vehicle_details.drivetrain?
                    <VehicleDetailsItem title="DRIVETRAIN" detail={stickerInfo.vehicle_details.drivetrain}/>
                    :
                    <React.Fragment/>
                  }
                  {
                    stickerInfo.vehicle_details.interior_color?
                    <VehicleDetailsItem title="INTERIOR" detail={stickerInfo.vehicle_details.interior_color}/>
                    :
                    <React.Fragment/>
                  }
                  {
                    stickerInfo.vehicle_details.exterior_color?
                    <VehicleDetailsItem title="EXTERIOR" detail={stickerInfo.vehicle_details.exterior_color}/>
                    :
                    <React.Fragment/>
                  }
                </div>
              </div>
            </div>
            {(stickerInfo.pricing_details &&  stickerInfo.pricing_details.total) ?
              <div className="sticker-suggested-price-container">
                <div className="sticker-suggested-price-label">
                  {`TOTAL ${(stickerInfo.data_details && stickerInfo.data_details.oem_quality)?"SUGGESTED":"PREDICTED"} PRICE`}
                </div>
                <div className="sticker-suggested-price">
                  {`$${enNumFormatter.format(stickerInfo.pricing_details.total)}`}
                </div>
              </div>
              :
              <React.Fragment/>
            }
          </div>
          <div className="sticker-body">
            {stickerInfo.standard_options ?
              <div className="sticker-standard-options-container">
                <div className="sticker-standard-options">
                  {/*
                    <div className="sticker-standard-options-title">
                      STANDARD OPTIONS
                    </div>
                  */}
                  <div className="sticker-standard-options-items">
                    {
                      Object.keys(stickerInfo.standard_options).map(option=>{
                        return(
                          <div className="sticker-standard-options-item" key={stickerInfo.standard_options[option].label}>
                            <div className="sticker-standard-options-item-label">
                              {stickerInfo.standard_options[option].label}
                            </div>
                            <div className="sticker-standard-options-item-detail">
                              <ul>
                                {stickerInfo.standard_options[option].detail && stickerInfo.standard_options[option].detail.map(detail=>{
                                  return(
                                    <li key={detail.description}>{`${detail.code}${detail.description?" - "+detail.description:""}`}</li>
                                  )
                                })}
                              </ul>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              :
              <React.Fragment/>
            }

            <div className="sticker-added-options-container">
              <div className="sticker-added-options">
                {stickerInfo.pricing_details && (stickerInfo.pricing_details.base) ?
                  <div className="sticker-added-options-base-suggested-price">
                    <div className="sticker-added-options-base-suggested-price-label">
                      BASE SUGGESTED PRICE
                    </div>
                    <div className="sticker-added-options-base-suggested-price-value">
                      {`$${enNumFormatter.format(stickerInfo.pricing_details.base)}`}
                    </div>
                  </div>
                  :<React.Fragment/>
                }
                <div className="sticker-added-options-items">
                  <div className="sticker-added-options-item">
                    <div className="sticker-added-options-item-label">
                      ADDED OPTIONS
                    </div>
                    <div className="sticker-added-options-item-detail">
                      <ul>
                        {
                          stickerInfo.added_options.map(option=>{
                            return(

                                  <li className={`sticker-added-options-item-detail-li${option.price?" sticker-added-options-item-detail-li-has-price":""}`}key={option.description}>
                                    <div className="sticker-added-options-item-detail-desc">{`${option.code}${option.description?" - "+option.description:""}`}</div>
                                    <div className="sticker-added-options-item-detail-price">
                                      {option.price?
                                        <>{`${(Math.sign(option.price)<0) ? '-':''}$${enNumFormatter.format(Math.abs(option.price))}`}</>
                                        :
                                        <React.Fragment/>
                                      }
                                    </div>
                                    {(option.details && option.details.length)?
                                        <ul>
                                          {option.details.map(detail=>{
                                            return(
                                              <li>
                                                <div className="sticker-added-options-item-detail-desc">{`${detail.code}${detail.description?" - "+detail.description:""}`}</div>
                                                {detail.price?
                                                  <div className="sticker-added-options-item-detail-price">{`${(Math.sign(detail.price)<0) ? '-':''}$${enNumFormatter.format(Math.abs(detail.price))}`}</div>
                                                  :
                                                  <React.Fragment/>
                                                }
                                              </li>
                                            )
                                          })}
                                        </ul>
                                      :
                                      <React.Fragment/>
                                    }
                                  </li>

                            )
                          })
                        }
                    </ul>
                  </div>
                  </div>
                </div>
                {stickerInfo.pricing_details && (stickerInfo.pricing_details.base || stickerInfo.pricing_details.total || stickerInfo.pricing_details.details)?
                  <div className="sticker-added-options-price-totals">
                    <div className="sticker-added-options-price-totals-label">
                      PRICE DETAILS
                    </div>
                    {(stickerInfo.pricing_details && stickerInfo.pricing_details.details)?
                      <div className="sticker-added-options-price-totals-items">
                        {stickerInfo.pricing_details.details.map(detail=>{
                          return(
                            <div className="sticker-added-options-price-totals-item" key={detail.label}>
                              <div className="sticker-added-options-price-totals-item-label">
                                {detail.label}
                              </div>
                              <div className="sticker-added-options-price-totals-item-price">
                                {`${(Math.sign(detail.price)<0) ? '-':''}$${enNumFormatter.format(Math.abs(detail.price))}`}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      :
                      <React.Fragment/>
                    }
                    <div className="sticker-added-options-total-suggested-price">
                      <div className="sticker-added-options-total-suggested-price-label">
                        {`TOTAL ${(stickerInfo.data_details && stickerInfo.data_details.oem_quality)?"SUGGESTED":"PREDICTED"} PRICE`}
                      </div>
                      <div className="sticker-added-options-total-suggested-price-value">
                        {`$${enNumFormatter.format(stickerInfo.pricing_details.total)}`}
                      </div>
                    </div>
                  </div>
                  :
                  <React.Fragment/>
                }
              </div>
              </div>

          </div>
          <div className="sticker-footer">
            {stickerInfo.data_details && stickerInfo.data_details.disclaimer}
          </div>
        {!(stickerInfo.data_details && stickerInfo.data_details.oem_quality)?
          <div className="sticker-overlay">
            PREDICTIVE DATA
          </div>
          :
          <React.Fragment/>
        }
        </div>
      </div>
    )
  }

  return(
    <div className="sticker-page-container">
      <div className="sticker-page">

        {(stickerInfo && !loading)?
          <>
            <div className="sticker-page-controls">
              <a
                className="sticker-page-control sticker-page-controls-print"
                href={stickerDownloadURL(id,token())}
                target="_blank"
              >
                <PrintIcon/>
              </a>
            </div>
            <Sticker/>
          </>
          :
          <>
            {errorLoading?
              <div className="sticker-page-error">
                There was a problem loading this document
              </div>
              :
              <div className="sticker-page-loading">
                ...
              </div>
            }
          </>
        }
      </div>
    </div>
  )
}
