const TEST_STICKER_INFO_DEFAULT = {
   "vehicle_details":{
      "year":2023,
      "model":"E63W4s",
      "make":"Mercedes",
      "logo":null,
      "vin":"W1KZF8KB5PB139693",
      "interior_color":'Brown',
      "exterior_color":'Black',
      "engine":null,
      "transmission":null,
      "drivetrain":null,
      "city_mpg":null,
      "highway_mpg":null,
      "TODO-additional_details":[
        {
          "label":"Vehicle Creation Date",
          "value":"05_31_2023",
          "type":"DATE"
        },
        {
          "label":"OEM SITE",
          "value":"www.mercedes.com",
          "type":"LINK"
        }
      ]
   },
   "added_options":[
      {
         "code":"DV8",
         "description":"Final Edition",
         "price":56118
      },
      {
         "code":"053",
         "description":"Active Distance Assist",
         "price":null
      }
   ],
   "pricing_details":{
     "total": 99999,
     "base": 89999,
     "details":[
      {
        "label":"Total Added Features",
        "price":1840
      },
      {
        "label":"Destination Charge",
        "price":995
      }
     ]
   },
   "standard_options":[
      {
         "label":"Safety Features",
         "details":[
            {
               "code":"U88",
               "description":"AMG DRIVE UNIT",
               "details":null
            },
            {
               "code":"U78",
               "description":"Nappa Leather Dashboard",
               "details":null

            }
         ]
      },
      {
         "label":"Performance Features",
         "details":[
            {
               "code":"U45",
               "description":"V8 Vortex Engine",
               "details":[
                 {
                   "code":"U45A",
                   "description":"Turbo"
                 },
                {
                   "code":"U45B",
                   "description":"Cold Air Intake"
                 }
               ]
            },
            {
               "code":"F17",
               "description":"36inch Brake Calipers",
               "details":null
            }
         ]
      },
      {
         "label":"Safety Features",
         "details":[
            {
               "code":"U88",
               "description":"AMG DRIVE UNIT",
               "details":null
            },
            {
               "code":"U78",
               "description":"Nappa Leather Dashboard",
               "details":null

            }
         ]
      },
      {
         "label":"Performance Features",
         "details":[
            {
               "code":"U45",
               "description":"V8 Vortex Engine",
               "details":[
                 {
                   "code":"U45A",
                   "description":"Turbo"
                 },
                {
                   "code":"U45B",
                   "description":"Cold Air Intake"
                 }
               ]
            },
            {
               "code":"F17",
               "description":"36inch Brake Calipers",
               "details":null
            }
         ]
      },
      {
         "label":"Safety Features",
         "details":[
            {
               "code":"U88",
               "description":"AMG DRIVE UNIT",
               "details":null
            },
            {
               "code":"U78",
               "description":"Nappa Leather Dashboard",
               "details":null

            }
         ]
      },
      {
         "label":"Performance Features",
         "details":[
            {
               "code":"U45",
               "description":"V8 Vortex Engine",
               "details":[
                 {
                   "code":"U45A",
                   "description":"Turbo"
                 },
                {
                   "code":"U45B",
                   "description":"Cold Air Intake"
                 }
               ]
            },
            {
               "code":"F17",
               "description":"36inch Brake Calipers",
               "details":null
            }
         ]
      }
   ],
  "data_details":{
    "disclaimer":"This is an example disclaimer for this document",
    "oem_quality":false
  }

}

const TEST_STICKER_INFO_VEHICLE_DETAILS_OVERFLOW = {
   "vehicle_details":{
      "year":2023,
      "model":"Very Long Model",
      "make":"Very Long Make",
      "logo":null,
      "vin":"W1KZF8KB5PB139693",
      "interior_color":'Brown',
      "exterior_color":'Black',
      "engine":null,
      "transmission":null,
      "drivetrain":null,
      "city_mpg":null,
      "highway_mpg":null,
      "trim": "Very Long Trim",
      "TODO-additional_details":[
        {
          "label":"Vehicle Creation Date",
          "value":"05_31_2023",
          "type":"DATE"
        },
        {
          "label":"OEM SITE",
          "value":"www.mercedes.com",
          "type":"LINK"
        }
      ]
   },
   "added_options":[
      {
         "code":"DV8",
         "description":"Final Edition",
         "price":56118
      },
      {
         "code":"053",
         "description":"Active Distance Assist",
         "price":null
      }
   ],
   "pricing_details":{
     "total": 99999,
     "base": 89999,
     "details":[
      {
        "label":"Total Added Features",
        "price":1840
      },
      {
        "label":"Destination Charge",
        "price":995
      }
     ]
   },
   "standard_options":[
      {
         "label":"Safety Features",
         "details":[
            {
               "code":"U88",
               "description":"AMG DRIVE UNIT",
               "details":null
            },
            {
               "code":"U78",
               "description":"Nappa Leather Dashboard",
               "details":null

            }
         ]
      },
      {
         "label":"Performance Features",
         "details":[
            {
               "code":"U45",
               "description":"V8 Vortex Engine",
               "details":[
                 {
                   "code":"U45A",
                   "description":"Turbo"
                 },
                {
                   "code":"U45B",
                   "description":"Cold Air Intake"
                 }
               ]
            },
            {
               "code":"F17",
               "description":"36inch Brake Calipers",
               "details":null
            }
         ]
      },
      {
         "label":"Safety Features",
         "details":[
            {
               "code":"U88",
               "description":"AMG DRIVE UNIT",
               "details":null
            },
            {
               "code":"U78",
               "description":"Nappa Leather Dashboard",
               "details":null

            }
         ]
      },
      {
         "label":"Performance Features",
         "details":[
            {
               "code":"U45",
               "description":"V8 Vortex Engine",
               "details":[
                 {
                   "code":"U45A",
                   "description":"Turbo"
                 },
                {
                   "code":"U45B",
                   "description":"Cold Air Intake"
                 }
               ]
            },
            {
               "code":"F17",
               "description":"36inch Brake Calipers",
               "details":null
            }
         ]
      },
      {
         "label":"Safety Features",
         "details":[
            {
               "code":"U88",
               "description":"AMG DRIVE UNIT",
               "details":null
            },
            {
               "code":"U78",
               "description":"Nappa Leather Dashboard",
               "details":null

            }
         ]
      },
      {
         "label":"Performance Features",
         "details":[
            {
               "code":"U45",
               "description":"V8 Vortex Engine",
               "details":[
                 {
                   "code":"U45A",
                   "description":"Turbo"
                 },
                {
                   "code":"U45B",
                   "description":"Cold Air Intake"
                 }
               ]
            },
            {
               "code":"F17",
               "description":"36inch Brake Calipers",
               "details":null
            }
         ]
      }
   ],
  "data_details":{
    "disclaimer":"This is an example disclaimer for this document",
    "oem_quality":true
  }

}
const TEST_STICKER_INFO  = [TEST_STICKER_INFO_DEFAULT,TEST_STICKER_INFO_VEHICLE_DETAILS_OVERFLOW]
export{TEST_STICKER_INFO}
