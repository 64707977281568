import AIP_DJAPI,{AIP_DJAPI_URLS} from '../../../api/aipDjapi'



export function getStickerInfo(id,jwt){
  let url = `${AIP_DJAPI_URLS.STICKER_INFO}/${id}?token=${jwt}`
  return AIP_DJAPI(
    url,
    {
      method: "GET",
      headers:{
        Accept: 'application/json',
      }
    }
  )
}
export function stickerDownloadURL(id,jwt){
  return`${AIP_DJAPI_URLS.STICKER_PRINT}/${id}?token=${jwt}`
}
