import packageJson from '../../package.json'

let baseUrl = "https://web-api.autoipacket.com/"

if(window.location.hostname !== 'document-viewer.autoipacket.com' && window.location.hostname !== 'www.document-viewer.autoipacket.com' && window.location.hostname !== 'document-viewer2.autoipacket.com' && window.location.hostname !== 'www.document-viewer2.autoipacket.com'){
  baseUrl = "https://djapi.autoipacket-stg.com/"
}

if (window.ENV && window.ENV.API_URL) {
  baseUrl = window.ENV.API_URL + '/'
}
else if (process.env.API_URL) {
  baseUrl = process.env.API_URL + '/'
}


const AIP_DJAPI_URLS = {
  BASE_URL: baseUrl,
  STICKER_INFO: `${baseUrl}v2/sticker-puller/context`,
  STICKER_PRINT: `${baseUrl}v2/sticker-puller/download`
}

//TODO Update Application Type Value
const AIP_DJAPI_HEADERS ={
  'AIP-Application-Type': 4,
  'AIP-Application-Version': packageJson.version
}

export default function AIP_DJAPI(url,params){
  if(params){
    if(params.headers){
      params.headers ={
        ...AIP_DJAPI_HEADERS,
        ...params.headers,
      }
    }
    else{
      params.headers ={
        ...AIP_DJAPI_HEADERS,
      }
    }
  }
  else{
    params = {
      headers:AIP_DJAPI_HEADERS
    }
  }

  return fetch(url,params).then((response)=>{
    return response
  })
}

export {AIP_DJAPI_URLS,AIP_DJAPI_HEADERS}
